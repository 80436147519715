import * as React from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener, Paper, useMediaQuery } from '@mui/material';

export default function Popover({ children, buttonElement }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const isMobile = useMediaQuery('(max-width:450px)');
  return (
    <React.Fragment>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <div>
          {buttonElement({ handleClick })}
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="top"
            sx={{
              margin: `0 0 65px ${isMobile ? '18px' : '118px'} !important`,
              zIndex: 9999999,
              '& aside': {
                width: 'unset !important',
                height: '400px !important',
              },
            }}
          >
            <Paper elevation={5}>{children({ handleClick })}</Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </React.Fragment>
  );
}
