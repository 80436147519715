import React from 'react';

const Cross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 19 18"
      class="conversations-visitor-close-icon"
    >
      <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
        <g
          fill="#ffffff"
          transform="translate(-927 -991) translate(900.277 962)"
        >
          <g transform="translate(27 29)">
            <path d="M10.627 9.013l6.872 6.873.708.707-1.415 1.414-.707-.707-6.872-6.872L2.34 17.3l-.707.707L.22 16.593l.707-.707L7.8 9.013.946 2.161l-.707-.708L1.653.04l.707.707L9.213 7.6 16.066.746l.707-.707 1.414 1.414-.707.708-6.853 6.852z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Cross;
