import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ChatBotPoper from './chatBotPoper';

const ChatBotComponent = ({params, getTarget}) => {
  return (
    <CookiesProvider>
      <ChatBotPoper params={params} getTarget={getTarget} />
    </CookiesProvider>
  );
};

export { ChatBotComponent };
