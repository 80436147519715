import React from 'react';

const Chat = ({color,...props}) => {
  return (
    <svg
      width="119"
      height="119"
      viewBox="0 0 119 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="59.5" cy="59.5" r="59.5" fill={color} />
      <path
        d="M53.6297 43.896C38.5255 43.896 26.1073 54.2826 26.1073 67.05C26.1073 72.1535 28.0869 77.3402 31.7172 81.3603L26.5907 87.2185C26.0863 87.7956 25.964 88.6153 26.2831 89.3146C26.6004 90.0139 27.2959 90.463 28.0639 90.463H55.5863C66.7383 90.463 81.1521 80.8599 81.1521 67.05C81.1521 54.2826 68.7338 43.896 53.6297 43.896ZM41.7598 70.6364C39.6026 70.6364 37.8467 68.8804 37.8467 66.7233C37.8467 64.566 39.6027 62.8102 41.7598 62.8102C43.9169 62.8102 45.6729 64.5662 45.6729 66.7233C45.6731 68.8804 43.9171 70.6364 41.7598 70.6364ZM53.6297 70.6364C51.4725 70.6364 49.7166 68.8804 49.7166 66.7233C49.7166 64.566 51.4726 62.8102 53.6297 62.8102C55.7868 62.8102 57.5428 64.5662 57.5428 66.7233C57.5428 68.8804 55.7869 70.6364 53.6297 70.6364ZM65.4995 70.6364C63.3423 70.6364 61.5864 68.8804 61.5864 66.7233C61.5864 64.566 63.3424 62.8102 65.4995 62.8102C67.6566 62.8102 69.4126 64.5662 69.4126 66.7233C69.4126 68.8804 67.6568 70.6364 65.4995 70.6364Z"
        fill="white"
      />
      <path
        d="M92.4194 63.4921L87.8128 58.1173C91.1011 54.4068 92.8915 49.8821 92.8915 45.2009C92.8914 33.3336 81.4807 23.6787 67.4561 23.6787C54.7739 23.6787 43.9994 31.582 42.0969 41.8687C45.6355 40.6663 49.6039 39.9828 53.6297 39.9828C70.7635 39.9828 84.8404 51.9501 85.0461 66.7231H90.9349C92.6062 66.7233 93.5038 64.7552 92.4194 63.4921Z"
        fill="white"
      />
    </svg>
  );
};

export default Chat;
