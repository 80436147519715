import React from 'react';

const User = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#292374" />
      <mask
        id="mask0_5_1320"
        style={{maskType:"alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <circle cx="32" cy="32" r="32" fill="#5985CF" />
      </mask>
      <g mask="url(#mask0_5_1320)">
        <path
          d="M19.2095 46.6886C18.6334 43.4676 19.871 40.1389 22.9138 38.9355C25.1589 38.0476 28.1452 37.2676 31.7078 37.2676C35.2655 37.2676 38.3506 38.0456 40.711 38.932C43.9227 40.1381 45.3081 43.6086 44.7041 46.9857L41.6294 64.1748H22.3374L19.2095 46.6886Z"
          fill="url(#paint0_linear_5_1320)"
        />
        <path
          d="M39.1667 43.9463C38.9211 42.979 38.49 42.0704 38.0727 41.1639C37.5057 39.9321 37.0694 38.2837 38.7957 38.2837C40.031 38.2837 42.0812 39.1278 43.9677 40.0592C46.7672 41.4416 48.3643 44.3864 48.3643 47.5086V64.1756H44.3029L39.1667 43.9463Z"
          fill="url(#paint1_linear_5_1320)"
        />
        <path
          d="M25.4437 43.9463C25.6893 42.979 26.1203 42.0704 26.5376 41.1639C27.1046 39.9321 27.5409 38.2837 25.8147 38.2837C24.5794 38.2837 22.5292 39.1278 20.6427 40.0592C17.8431 41.4416 16.2461 44.3864 16.2461 47.5086V64.1756H20.3075L25.4437 43.9463Z"
          fill="url(#paint2_linear_5_1320)"
        />
        <circle cx="32.0013" cy="23.3507" r="11.2432" fill="white" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5_1320"
          x1="31.9834"
          y1="56.0519"
          x2="31.9834"
          y2="81.4361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_1320"
          x1="47.7245"
          y1="48.4374"
          x2="42.14"
          y2="48.4374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_1320"
          x1="16.8859"
          y1="48.4374"
          x2="22.4704"
          y2="48.4374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default User;
