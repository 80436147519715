import React from 'react';

const Header = ({color,...props}) => {
  return (
    <svg
      width="561"
      height="261"
      viewBox="0 0 561 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99638e-05 -58L568.775 -58V261C568.775 261 579.364 129.934 455.322 129.934C367.101 129.934 174.047 129.934 69.9075 129.934C31.2475 129.934 1.16673e-05 98.5937 2.60595e-05 59.9338L6.99638e-05 -58Z"
        fill={color}
      />
    </svg>
  );
};

export default Header;
