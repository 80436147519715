import { Button } from '@mui/material';
import React from 'react';

const ButtonComponent = ({ text = '' }) => {
  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        color: '#6D76A9',
        // border: '2px solid #6D76A9',
        borderRadius: '20px',
        // height: '60px',
        // width: '160px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // cursor: 'pointer',
        height: '38px',
        width: '105px',
        fontFamily: 'Inter',
        fontWeight: 500,
      }}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
