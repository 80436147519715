import React from 'react';

const Arrow = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="49"
      viewBox="0 0 64 49"
      fill="none"
    >
      <g clip-path="url(#clip0_103_2703)">
        <path
          d="M53.4111 28.0665L22.193 5.31732C17.6537 2.00935 13.0966 2.14398 12.0146 5.61778L4.64166 29.287C3.55956 32.7608 7.23402 35.4596 12.849 35.3146L51.4636 34.3185C57.0785 34.1737 57.9505 31.3745 53.4111 28.0665Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_103_2703">
          <rect
            width="55.511"
            height="32.9597"
            fill="white"
            transform="translate(10.6882 0.926758) rotate(17.3017)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrow;
