import { Badge, Box, Divider, IconButton, Paper, Tooltip } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import Popover from './popover';
import EmojiPicker from 'emoji-picker-react';
import AgeGroup from './svg/ageGroup';
import Header from './svg/header';
import AvatarSvg from '../components/svg/avatar';
import User from './svg/user';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import MicNoneIcon from '@mui/icons-material/MicNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import SyncIcon from '@mui/icons-material/Sync';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import gfm from 'remark-gfm';
import ConfirmationDialog from './confirmationDialog';
import useIsInViewport from './useInViewPort';
import useLocalStorage from 'use-local-storage';
import { Offline } from 'react-detect-offline';
import CloseIcon from '@mui/icons-material/Close';
import MultipleSelectCheckmarks from './checklist';
import ButtonComponent from './button';
import { useTheme } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme?.palette?.background?.paper}`,
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid white',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
export const serverBaseURL = 'https://vitabot-ai-516bfb070b90.herokuapp.com/v1';

const URL = 'https://supplement-assistant.healthtimes.co.uk/'

function LinkRenderer(props) {
  return (
    <span
      onClick={() => {
        window.parent.postMessage(
          {
            tracking: 'vitabot',
            event: 'link_clicked',
            link_url: props.href, // Link of the URL clicked by the user
          },
          '*'
        );
      }}
    >
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    </span>
  );
}

const hexToRgba=(hex)=>{
      const opacity = "0.5";
      // Convert each hex character pair into an integer
      const red = parseInt(hex.substring(1, 3), 16);
      const green = parseInt(hex.substring(3, 5), 16);
      const blue = parseInt(hex.substring(5, 7), 16);
      return `rgba(${red}, ${green}, ${blue}, ${opacity})`
       
}

function AssistantMsg(props) {
  const [showLikeDisLike, setShowLikeDisLike] = useState(false);
  const { index, setChatData, chat, item, setItem, container, isFullscreen,main } =
    props;
  const LikeIcon = chat?.isLike ? ThumbUpIcon : ThumbUpOutlinedIcon;
  const DisLikeIcon = chat?.isDislike
    ? ThumbDownAltIcon
    : ThumbDownOffAltOutlinedIcon;
  const chatRef = useRef();
  const isDislikeOrLike =
    !!(chat?.isLike || chat?.isDislike) || showLikeDisLike;

  const isInViewport = useIsInViewport(chatRef);

  // useEffect(() => {
  //   if (isInViewport) {
  //     const alreadySeen = item || [];
  //     if (!alreadySeen?.includes(index)) {
  //       setItem([...(alreadySeen || []), index]);
  //     }
  //   }
  // }, [isInViewport]);

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box
      onMouseEnter={() => setShowLikeDisLike(true)}
      onMouseLeave={() => setShowLikeDisLike(false)}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: '10px', // svg: {
          //   width: '80%',
          // },
        }}
      >
        <AvatarSvg width="40" height="40" />
        <Box
          className="leftMessage"
          ref={chatRef}
          sx={{
            maxWidth: 'fit-content',
            backgroundColor: '#F0F0F8',
            fontWeight: '500',
            fontFamily: 'Inter',
            // display: 'flex',
            // alignItems: 'center',
            borderRadius: chat.content.length < 20 ? '35px' : '20px',
            width: isFullscreen ? '90%' : '70%',
            // justifyContent: 'center',
            padding: '15px 20px',
            wordBreak: 'break-word',
            fontSize: '14px',
            transition: 'all 1.5s ease-out',
            whiteSpace: 'pre-line',
          }}
        >
          <ReactMarkdown components={{ a: LinkRenderer }}>
            {chat.content}
          </ReactMarkdown>
        </Box>
      </Box>
      <Box ml={'55px'} mt={'5px'} display={'flex'} gap="5px" flexWrap={'wrap'}>
        {/* <MultipleSelectCheckmarks value={personName} handleChange={handleChange} size={"small"} multiple container={container}/> */}
        {/* <ButtonComponent text='hello'/>
      <ButtonComponent text='hello'/>
      <ButtonComponent text='hello'/> */}
      </Box>
      <Box ml={'55px'} mt={'5px'} height={'27px'}>
        {isDislikeOrLike && (
          <>
            {' '}
            <LikeIcon
              style={{
                cursor: 'pointer',
                width: '0.8em',
                height: '0.8em',
                ...(chat?.isLike
                  ? { color: main }
                  : { stroke: main, fill: main, strokeWidth: 0 }),
              }}
              onClick={() => {
                setChatData((pre) => {
                  return pre.map((item, id) =>
                    index === id
                      ? { ...item, isDislike: false, isLike: !item?.isLike }
                      : item
                  );
                });
              }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <DisLikeIcon
              style={{
                cursor: 'pointer',
                width: '0.8em',
                height: '0.8em',
                ...(chat?.isDislike
                  ? { color: main }
                  : { stroke: main, fill: main, strokeWidth: 0 }),
              }}
              onClick={() => {
                setChatData((pre) => {
                  return pre.map((item, id) =>
                    index === id
                      ? { ...item, isDislike: !item?.isDislike, isLike: false }
                      : item
                  );
                });
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

const ChatBotUi = ({
  handleClick,
  containerRef,
  setTotalUnreadMessage,
  chatData,
  setChatData,
  isFullscreen = false,
  params,
  getTarget
  // open,
}) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const [inputText, setInputText] = useState('');
  const [botTyping, setBotTyping] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(URL);
  const [isSpeak, setIsSpeak] = useState(false);
  const [isUserScroll, setIsUserScroll] = useState(false);

  const [userId, setUserId] = useLocalStorage('user_id');
  const [conversationId, setConversationId] = useLocalStorage(
    'conversation_id',
    null
  );
  const [ userAgent ] = useLocalStorage('user_agent', params.userAgent || "")
  const [ location ] = useLocalStorage('location_data', params.location || "{}")

  const { transcript, resetTranscript } = useSpeechRecognition();

  const inputRef = useRef();

  const handleEmojiClick = (emojiData) => {
    setInputText((input) => input + emojiData.emoji);
  };
  const handleFileChange = (e) => {
    console.log(e.target.files, 'e.target.files');
  };

  useEffect(() => {
    if (containerRef?.current && window.innerHeight < 600) {
      containerRef.current.style.height = `${window.innerHeight - 115}px`;
    } else {
      if (containerRef?.current) containerRef.current.style.height = `${576}px`;
    }
  }, []);

  const addUser = async () => {
    if (!userId) {
      try {
        const resp = await fetch(`${serverBaseURL}/users`, {
          method: 'POST',
        });
        const data = await resp.json();
        setUserId(data.user_id);
        window.top?.postMessage({ userId: data.user_id }, getTarget(params.url));
        return data.user_id;
      } catch (error) {
        // console.log(error);
      }
    } else {
      return userId;
    }
  };
  const addConversation = async (url) => {
    if (!conversationId) {
      try {
        const resp = await fetch(`${serverBaseURL}/conversations`, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: url || currentUrl }),
        });
        const data = await resp.json();
        setConversationId(data.conversation_id);
        window.top?.postMessage({ conversationId: data.conversation_id }, getTarget(url));
        return data.conversation_id;
      } catch (error) {
        console.log(error);
      }
    } else {  
      return conversationId;
    }
  };
  const [item, setItem] = useLocalStorage('alreadySeen');

  useEffect(() => {
    return () => {
      if (botTyping) {
        setTotalUnreadMessage(1);
      } else {
        setTotalUnreadMessage(0);
      }
    };
  }, [botTyping]);

  const getConversation = async (url) => {
    try {
      const conversationId = await addConversation(url);
      const resp = await fetch(
        `${serverBaseURL}/conversations/${conversationId}`
      );
      const data = await resp.json();
      setChatData(data.transcript);
      const alreadySeen = item || [];
      const filterAssistant = data.transcript
        .map((item, index) => (item.role === 'assistant' ? index : 'a'))
        .filter((item) => item !== 'a');
      // setItem([...new Set([...(alreadySeen || []), ...filterAssistant])]);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const search =
      typeof window !== 'undefined' ? window.location.search.substring(1) : '';
   
    if (search) {
      setCurrentUrl(params?.url || URL);
    }
    // setPrimaryColor(params.primaryColor)
    if (conversationId === '') {
      getConversation(params?.url || URL);
    }
  }, [conversationId]);

  const sendMessage = async (msg) => {
    if (/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/g.test(msg)) {
      window.parent.postMessage(
        {
          tracking: 'vitabot',
          event: 'newsletter_subscribed',
        },
        '*'
      );
    }
    const userMsg = chatData.filter((i) => i.role === 'user').length;
    if (userMsg === 0) {
      window.parent.postMessage(
        {
          tracking: 'vitabot',
          event: 'chat_started',
        },
        '*'
      );
    }
    window.parent.postMessage(
      {
        tracking: 'vitabot',
        event: 'message_sent',
        count: userMsg + 1, // eg 2,3,4. Nth message sent by the user for that particular conversation
      },
      '*'
    );

    let newText = '';
    const conversationId = await addConversation();
    const userID = await addUser();
    const payload = {
      input_txt: { role: 'user', content: msg },
      conversation_id: conversationId,
      user_id: userID,
      userAgent,
      location: JSON.parse(location || "{}")
    };
    await fetchEventSource(`${serverBaseURL}/send-message`, {
      method: 'POST',
      headers: {
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log('Client-side error ', res);
        }
      },
      async onmessage(event) {
        try {
          const isNumber = isNaN(event.data);
          if (isNumber) {
            const parsedData = JSON.parse(event.data);
            const preChatData = [
              ...chatData,
              { role: 'user', content: inputText },
            ];
            setChatData([
              ...preChatData,
              { content: parsedData.message, role: 'assistant' },
            ]);
          } else {
            throw Error;
          }
        } catch (error) {
          newText += `${event.data}`;
          await new Promise((res) => {
            setTimeout(() => {
              res();
            }, 500);
          });
          const preChatData = [
            ...chatData,
            { role: 'user', content: inputText },
          ];
          setChatData([
            ...preChatData,
            { content: newText, role: 'assistant' },
          ]);
        }
      },
      onclose() {
        setBotTyping(false);
      },
      onerror(err) {
        // console.log('There was an error from server', err);
        setBotTyping(false);
      },
    });
  };

  const scrollContainer = useRef();
  useEffect(() => {
    if (!isUserScroll) {
      scrollContainer.current.scrollTop = scrollContainer?.current.scrollHeight;
    }
  }, [chatData?.length, chatData?.slice(-1)?.[0]?.content?.length, item]);

  // useEffect(() => {
  //   return () => {
  //     const alreadySeen = item;
  //     const filterAssistant = chatData
  //       .map((item, index) => (item.role === 'assistant' ? index : 'a'))
  //       .filter((item) => item !== 'a');
  //     if (alreadySeen) {
  //       setTotalUnreadMessage(
  //         filterAssistant.length >= alreadySeen.length
  //           ? filterAssistant.length - alreadySeen.length
  //           : 0
  //       );
  //     }
  //     // setTotalUnreadMessage(0);
  //     // if (!open) {
  //     //   setTotalUnreadMessage(1);
  //     // } else {
  //     //   if (alreadySeen) {
  //     //     setTotalUnreadMessage(
  //     //       filterAssistant.length >= alreadySeen.length
  //     //         ? filterAssistant.length - alreadySeen.length
  //     //         : 0
  //     //     );
  //     //   }
  //     // }
  //   };
  // }, [chatData, item, botTyping]);

  useEffect(() => {
    const search =
      typeof window !== 'undefined' ? window.location.search.substring(1) : '';
    if (search) {
      setCurrentUrl(params?.url || URL);
    }
    // addUser();
    // addConversation(params?.url || URL);
    if (conversationId) {
      getConversation(params?.url || URL);
    } else {
      setChatData([
        {
          role: 'assistant',
          content:
            "Hello! I’m AI supplement assistant, your guide through the sometimes baffling business of supplements. Stick with me and I'll help you find the best ones for your needs. Let's start with your name.",
        },
      ]);
    }
  }, []);

  // useEffect(() => {
  //   const search =
  //     typeof window !== 'undefined' ? window.location.search.substring(1) : '';
  //   let params;
  //   if (search) {
  //     params = JSON.parse(
  //       '{"' +
  //         decodeURI(search)
  //           .replace(/"/g, '\\"')
  //           .replace(/&/g, '","')
  //           .replace(/=/g, '":"') +
  //         '"}'
  //     );
  //     // console.log(params, 'params');
  //     setCurrentUrl(params?.url || 'https://vitabot.healthtimes.co.uk');
  //   }
  //   if(conversationId){
  //     getConversation(params?.url || 'https://vitabot.healthtimes.co.uk');
  //   }
  // }, []);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef?.current.addEventListener('wheel', () => {
        setIsUserScroll(true);
      });
    }
  }, []);
  const container = useRef();
  return (
    <Paper
      sx={{
        height: '100%',
        position: 'sticky',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
      elevation={6}
      ref={container}
    >
      <Box>
        <Box
          position={'relative'}
          sx={{
            '.headerImage': {
              width: '100%',
              borderRadius: '20px',
              position: 'absolute',
              top: '-28px',
            },
          }}
        >
          {isFullscreen ? (
            <Box
              sx={{
                height: '100px',
                width: '100%',
                backgroundColor: main,
                borderTopLeftRadius: '20px',
              }}
            />
          ) : (
            <Header className="headerImage" color={main}/>
          )}
          <Box
            display={'flex'}
            justifyContent="space-between"
            position={'absolute'}
            top={'9px'}
            left={'17px'}
            width="calc(100% - 17px)"
          >
            <Box display={'flex'} gap={'5px'}>
              <Box
                sx={{
                  height: '80px',
                  width: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <AvatarSvg />
                </StyledBadge>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <small
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 300,
                    color: 'white',
                  }}
                >
                  {' '}
                  Chat with
                </small>
                <span
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontWeight: 600,
                    color: 'white',
                  }}
                >
                  AI Supplement Assistant
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                marginRight: '17px',
              }}
            >
              <Tooltip title="Start a new conversation" placement="top" arrow>
                <ConfirmationDialog
                  container={container.current}
                  buttonEle={({ handleClickOpen }) => (
                    <SyncIcon
                      style={{ color: 'white', cursor: 'pointer' }}
                      onClick={() => {
                        handleClickOpen();
                      }}
                    />
                  )}
                  handleConfirm={() => {
                    window.parent.postMessage(
                      {
                        tracking: 'vitabot',
                        event: 'start_new_conversation',
                      },
                      '*'
                    );
                    setConversationId('');
                  }}
                  content={'Are you want to start new conversation'}
                  title=""
                />
              </Tooltip>
              {!isFullscreen && (
                <KeyboardArrowDownIcon
                  style={{ color: 'white', cursor: 'pointer' }}
                  onClick={handleClick}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: '110px',
          width: '100%',
          fontSize: '14px',
          color: 'red',
          fontFamily: "'Inter'",
        }}
      >
        <Offline>Check Your Internet Connection.</Offline>
      </div>
      <Box
        sx={{
          height: 'calc(100% - 262px)',
          width: '100%',
          position: 'absolute',
          top: '130px',
        }}
      >
        <Box
          margin={'0 25px'}
          pr={'5px'}
          height={'100%'}
          overflow={'auto'}
          display={'flex'}
          // gap={'25px'}
          flexDirection={'column'}
          position={'relative'}
          ref={scrollContainer}
          onTouchEnd={() => {
            setIsUserScroll(true);
          }}
        >
          {chatData.map((chat, index) => (
            <Fragment key={chat.content + index}>
              {chat.role === 'assistant' && (
                <AssistantMsg
                  chat={chat}
                  index={index}
                  setChatData={setChatData}
                  item={item}
                  setItem={setItem}
                  container={container.current}
                  isFullscreen={isFullscreen}
                  main={main}
                />
              )}
              {chat.role === 'user' && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    className="rightMessage"
                    sx={{
                      maxWidth: 'fit-content',
                      backgroundColor: main,
                      fontWeight: '500',
                      fontFamily: 'Inter',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: chat.content.length < 20 ? '35px' : '20px',
                      width: isFullscreen ? '90%' : '70%',
                      justifyContent: 'center',
                      padding: '15px 17px',
                      color: 'white',
                      wordBreak: 'break-word',
                      fontSize: '14px',
                      marginBottom: '25px',
                    }}
                  >
                    <ReactMarkdown
                      children={chat.content}
                      remarkPlugins={[gfm]}
                    />
                    {/* {chat.content} */}
                  </Box>
                  <User />
                </Box>
              )}
            </Fragment>
          ))}

          {/* <Box
            sx={{
              position: 'absolute',
              // zIndex: '9999',
              bottom: '0',
              background: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              variant="outlined"
              sx={{
                color: '#6D76A9',
                border: '2px solid #6D76A9',
                borderRadius: '30px',
                height: '60px',
                width: '160px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              Yes, i am
            </Box>
            <Box
              variant="outlined"
              sx={{
                color: '#6D76A9',
                border: '2px solid #6D76A9',
                borderRadius: '30px',
                height: '60px',
                width: '160px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              No, i'm not
            </Box>
          </Box> */}
        </Box>
      </Box>
      <Box
        position={'absolute'}
        bottom={0}
        width={'100%'}
        paddingBottom={'20px'}
      >
        <Divider sx={{ margin: '0 25px' }} />
        <Box
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
            if (inputText) {
              setIsUserScroll(false);
              setBotTyping(true);
              setChatData((pre) => [
                ...pre,
                { role: 'user', content: inputText },
              ]);
              setInputText('');
              sendMessage(inputText);
            }
          }}
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            padding: '0 25px',
            '& .messageInput': {
              border: 'none',
              height: '64px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px',
              fontFamily: 'Inter',
              padding: '18px 0',
              resize: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&:disabled': {
                background: 'transparent',
              },
              '&:focus-visible': {
                border: 'none !important',
                outline: 'none',
              },
            },
          }}
        >
          <textarea
            className="messageInput"
            placeholder="Enter Your Message..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value.trimStart())}
            disabled={botTyping}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (inputText) {
                  setIsUserScroll(false);
                  setBotTyping(true);
                  setChatData((pre) => [
                    ...pre,
                    { role: 'user', content: inputText },
                  ]);
                  setInputText('');
                  sendMessage(inputText);
                }
              }
            }}
          />

          <Tooltip title="Tap and speak">
            <IconButton
              className={isSpeak ? 'micBtn' : ''}
              disabled={botTyping}
            >
              {!isSpeak ? (
                <MicNoneIcon
                  style={{ fill: main }}
                  onClick={() => {
                    setIsSpeak(true);
                    SpeechRecognition.stopListening();
                    resetTranscript();
                    SpeechRecognition.startListening();
                  }}
                />
              ) : (
                <CloseIcon
                  style={{ fill: main, zIndex: 99 }}
                  onClick={() => {
                    setIsSpeak(false);
                    SpeechRecognition.stopListening();
                    setInputText(transcript);
                    resetTranscript();
                  }}
                />
              )}
            </IconButton>
          </Tooltip>

          <IconButton
            type="submit"
            disabled={!inputText}
            sx={{
              width: '50px',
              height: '50px',
              background: main,
              borderRadius: '11px',
              '&:hover': {
                background: main,
              },
              '&:disabled': {
                background: hexToRgba(main),
                cursor: 'not-allowed',
                pointerEvents: 'unset',
              },
              svg: {
                color: 'white',
              },
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
        <small
          style={{
            display: 'block',
            textAlign: 'end',
            padding: '0 25px 3px 0',
            fontSize: '11px',
            fontFamily: 'Inter',
          }}
        >
          Shift + Enter to add a new line
        </small>
        <Box
          padding={'0 25px'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box display={'flex'}>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={inputRef}
              onChange={handleFileChange}
            />
            {/* <IconButton
              sx={{ p: 0 }}
              onClick={() => inputRef?.current?.click()}
            >
              <AttachFileIcon />
            </IconButton> */}
            <Popover
              buttonElement={({ handleClick }) => (
                <IconButton onClick={handleClick} sx={{ p: 0 }}>
                  <EmojiEmotionsOutlinedIcon />
                </IconButton>
              )}
            >
              {() => (
                <EmojiPicker
                  onEmojiClick={(...e) => {
                    // handleClick();
                    handleEmojiClick(...e);
                  }}
                  autoFocusSearch={false}
                />
              )}
            </Popover>
          </Box>
          <Box
            sx={{
              svg: {
                height: '25px',
                position: 'relative',
                right: '-32px',
              },
            }}
          >
            <AgeGroup />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ChatBotUi;
